import { GridContent, GridRow } from "~/components/primitives/GridRow";
import { Header2 } from "~/components/primitives/text/Header";
import { cn } from "~/utils/cn";

type SectionHeaderProps = {
  title: string;
  buttons?: React.ReactNode;
  buttonsPosition?: "stacked" | "inline";
  hidePattern?: boolean;
  className?: string;
  removeBorderT?: boolean;
};

export function SectionHeader({
  title,
  buttons,
  buttonsPosition = "stacked",
  hidePattern = false,
  className,
  removeBorderT = false,
}: SectionHeaderProps) {
  const buttonWrapperStyles = cn({
    "flex w-full gap-3 py-1.5 sm:w-auto sm:flex-row sm:py-0":
      buttonsPosition === "stacked",
    "": buttonsPosition === "inline",
  });
  return (
    <GridRow removeBorderT={removeBorderT}>
      <GridContent
        removePaddingX
        removePaddingY
        className={cn(
          "col-span-1 flex h-44 flex-col pb-3 sm:col-span-12",
          hidePattern ? "justify-end" : "justify-between",
          buttons && buttonsPosition === "stacked" && "pb-0 sm:pb-3",
          className
        )}
      >
        {!hidePattern && (
          <div className="h-16 w-full bg-pattern-crosses-fade" />
        )}
        <div
          className={cn(
            "flex w-full items-start justify-between gap-3 sm:flex-row sm:items-end sm:gap-0 sm:divide-y-0",
            buttonsPosition === "stacked" &&
              "flex-col divide-y divide-grid-dimmed"
          )}
        >
          <Header2 variant="base" className="flex w-full px-3">
            {title}
          </Header2>
          {buttons && (
            <div className={buttonWrapperStyles}>
              <>{buttons}</>
            </div>
          )}
        </div>
      </GridContent>
    </GridRow>
  );
}
